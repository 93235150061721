import {runInAction, toJS} from 'mobx';
import {Request, Response} from 'System/utilities/network.js';
import langstore from 'System/i18n/translator.js';
import {deleteDB} from 'idb'
import {getParameterByName} from 'System/utilities/urls.js';
import {is_touch_device} from 'System/utilities/devices.js';
import ReactGA from "react-ga4";
import {getBeVersion} from "System/actions/common";

const init = async function (_token, welcomeMessage, internalUser) {

    let i;
    let loadingKey = 'appinit';
    let token = this.userToken;
    let upgraded = await this.appUpgrade();

    if (token) {
        internalUser = this.userInfo.internal;
    }

    if (_token) {
        token = _token;
    }

    if (upgraded === true) {
        token = false;
    }

    this.loadingAdd(loadingKey);

    if (token && !internalUser) {
        // carico risorse
        await Promise.all([
            //this.updateComunicazioni(),
            //this.updateCapigruppo(),
            //this.updateNumeratori(),
            //this.updateStatiOrdine(),
            //this.updateStatiReclamo(),
            //this.updateEventiOrdini(),
            //this.updateStatiOrdineAttivo(),
            this.updateLanguages(),
            this.updateMenu(),
            this.updateAutorizzazioni(),
            this.updateEnabledEmails(),
            this.getInfoUtente()
        ]);

        this.menuLoad();

        await this.languageChange(this.language);

        if (welcomeMessage) {
            this.notificationAdd(`${langstore.t("user_welcome", "Bentornato")} ${welcomeMessage}`, 'userformlogin');
        }
    }
    else {

        // this.languages
        await this.updateLanguages();

        runInAction(() => {
            this.menus = [];
        });

        let activeLanguages = this.languages;

        /*
          Settaggio lingua di default
          Se è già impstata una lingua la usa altrimenti
          assume che lingua segnalata nel config (tipicamente "it") esista sempre
        */
        let languageTemp = this.language ?? this.config.languages.languageDefault;

        /*
            Don't need to set a language if is already settled
         */
        if (this.language === null) {

            /*
              Cerca lingua di sistema;
              Se non è "it", lingua default diventa "en", ma solo se "en" esiste nelle lingue supportate
            */
            let languageSystem = navigator.language.split("-");
            languageSystem = languageSystem[0];
            if (languageSystem !== "it") {
                for (i = 0; i < activeLanguages.length; i++) {
                    if ("en" === activeLanguages[i].iso) {
                        languageTemp = activeLanguages[i].iso;
                    }
                }
            }

            /*
              Se lingua di sistem esiste nella lista lingue, scelgo quella
              Sovrascrivo lingua default
            */
            for (i = 0; i < activeLanguages.length; i++) {
                if (languageSystem === activeLanguages[i].iso) {
                    languageTemp = activeLanguages[i].iso;
                }
            }

            /*
              Se c'è una lingua in cache ed esiste nella lista lingue, scelgo quella
              Sovrascrive lingua sistema
            */
            if (this.language) {
                for (i = 0; i < activeLanguages.length; i++) {
                    if (this.language === activeLanguages[i].iso) {
                        languageTemp = activeLanguages[i].iso;
                    }
                }
            }
        }

        if (!internalUser) {
            await this.languageChange(languageTemp);
            if (welcomeMessage) {
                this.notificationAdd(langstore.t("user_welcome", "Bentornato" + " " + welcomeMessage), 'userformlogin');
            }
        }
    }

    runInAction(() => {
        this.appReady = true;
        this.loadingRemove(loadingKey);
    });

    // this.routeReload(); // serve?
}

const initCore = function () {
    this.uiTouch = is_touch_device();
}

const clearStorage = function (appIndex) {
    Object.keys(localStorage).forEach(lsk => {
        if (lsk.indexOf(`${appIndex}_`) === 0) {
            localStorage.removeItem(lsk);
        }
    });
}

const menuLoad = function () {
    let menus = toJS(this.menus)
    let routes = toJS(this.routes);

    //console.log(menus)
    if (menus) {
        for (var i = 0; i < menus.length; i++) {
            let menu = menus[i];
            let routeAdded = false;
            for (var ii = 0; ii < routes.length; ii++) {
                let id = routes[ii].id;
                if (routes[ii].path === menu.path) {
                    menus[i].routeId = id;
                    break;
                }
            }
        }
    }

    runInAction(() => {
        this.menus = menus;
    });
}

const userLogin = async function (microsoftToken = null) {
    let loadingKey = 'userlogin';
    let url = this.config.paths.apiURL + "profilo/login";
    let userName = this.formData.loginEmail;
    let userPassword = this.formData.loginPassword;

    // Fallback if user does not enter "PC-" suffix
    if (!isNaN(userName)) {
        userName = 'PC-' + userName;
    }

    let params = {username: userName, password: userPassword};

    if (microsoftToken) {
        params.microsoftToken = microsoftToken;
        params.password = 'null'
    }

    // spinner
    this.loadingAdd(loadingKey);

    // API call
    let response = await this.dataLayer({
        url: url,
        cacheAge: 0,
        params: params,
    });

    await this.userAuth(response, loadingKey);
}

const trySsoLogin = async function (token) {
    const sso_token = token && token.length > 0 ? token : getParameterByName('ssoToken');

    if (sso_token) {
        this.ssoLoginRunning = true;

        let upgraded = await this.appUpgrade();
        if (upgraded === true) {
            this.userToken = null;
        }

        let loadingKey = 'userlogin';
        let url = this.config.paths.apiURL + "profilo/ssoLogin";
        let params = {sso_token}

        this.loadingAdd(loadingKey);

        let response = await this.dataLayer({
            url: url,
            cacheAge: 0,
            params: params,
        });

        let login = await this.userAuth({
            data: response.data
        });

        this.routeChange(this.config.routes.index);
        this.loadingRemove(loadingKey);
        this.ssoLoginRunning = false;
    }
}

const userAuth = async function (response, loadingKey) {
    // business logic
    runInAction(() => {
        if (response) {
            this.dataOrder = response.data.dettaglio;
        }
        if (loadingKey)
            this.loadingRemove(loadingKey);
    });
    // data management

    runInAction(() => {
        if (response) {
            let profilo = response.data.profilo;
            this.userInfo = {
                surname: profilo.cognome,
                name: profilo.ds_utente,
                fax: profilo.fax,
                username: profilo.username,
                phone: profilo.telefono,
                services: profilo.services,
                internal: profilo.isInternal || false,
                commercialPersonID: profilo.agente && profilo.agente.c_agente ? profilo.agente.c_agente : null,
                allCommercialPersonID: profilo.agenteAllCodes || [],
                salesperson: profilo.agente && profilo.agente.c_agente ? profilo.agente.c_agente : null, // DUPLICATED. use commercialPersonID https://github.com/arredo3/customer2.0-frontend/commit/1c5619483d22cdbe8ffbabd7bba5bc4f8562ae45
                impersonatingFrom: profilo.impersonificatingFrom || false,
                fastconfirm: profilo.fastconfirm || false,
                pricelists: profilo.listini_abilitati || null
            };
            this.userToken = response.data.token;
            // QUICK FIX TO BE CHECKED
            if (profilo.linguaISO) {
                if (profilo.linguaISO.trim() === '*') {
                    this.language = this.config.languages.languageDefault
                } else {
                    this.language = profilo.linguaISO
                }
            }

            this.userService = profilo.currentService;

            if (loadingKey)
                this.loadingRemove(loadingKey);

            ReactGA.event({
                category: "user",
                action: "user logged in",
                label: "user logged in", // optional
            });


            // init
            this.init(response.data.token, profilo.ds_utente, profilo.isInternal || false);
        }
    });
}

const userLogout = async function () {

    if (this.userToken) {

        var store = this;
        var requestBody = new Request();
        var request = {
            method: 'post', headers: {
                "Content-Type": "application/json",
                "x-access-token": store.userToken
            }
        };
        var url = store.config.paths.apiURL + "profilo/logout";

        request.body = JSON.stringify(requestBody);

        fetch(url, request).then(function (response) {
            return response.json();
        }).then(function (result) {
            var response = new Response(result);
        });

        this.notificationAdd(langstore.t("user_goodbye", "Utente scollegato"), 'userreset');

        ReactGA.event({
            category: "user",
            action: "user logged out",
            label: "user logged out", // optional
        });
    }

    const loadingKey = "disconnecting";

    this.loadingAdd(loadingKey);

    if (this.networkDBStore) {
        await this.networkDBStore.clear('networkdata');
    }

    let indexCurrent = localStorage.getItem(`app_index_${this.namespaceIndex}`) || "__new__";

    clearStorage(indexCurrent);

    this.loading = [];
    if (!getParameterByName('ssoToken')) {
        this.route = "/login";
    }

    this.loadingRemove(loadingKey);

    runInAction(() => {
        this.userToken = null;
        this.userService = null;
        this.userTokenRoot = null;
        this.uerInfoRoot = {};
        this.userServiceRoot = null;
        this.formData = {};
        this.menus = null;
        this.cacheParams = null;
        this.userInfo = {
            name: "",
            email: "",
            title: ""
        };
        this.selfCliente = null;
    });
}

const userReset = async function () {
    runInAction(() => {
        this.appReset();
        this.userLogout();
    });
}

const passwordReset = async function () {
    let loadingKey = 'passwordReset';
    this.loadingAdd(loadingKey);

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + "password/reset",
        cacheAge: 0,
        params: {
            c_utente: this.formData.loginID
        },
        rawResponse: true
    });

    runInAction(() => {
        this.formData = {};
        if (response) {
            if (response.status === "OK") {
                this.modalOpen("ModalReset", {
                    title: langstore.t("user_resetpw_title", "Istruzioni inviate"),
                    content: langstore.t("user_resetpw_body", "Controlla la casella email, abbiamo inviato le istruzioni per procedere al reset della password all'indirizzo "),
                    email: response.data.confirmationEmail
                });
            } else {
                this.modalOpen("ModalReset", {
                    title: langstore.t("ui_errortitle", "C'è stato un problema"),
                    content: response.message
                });
            }
        }
        this.loadingRemove(loadingKey);
    });
}

const startPasswordReset = async function () {
    let loadingKey = 'passwordResetProcedure';
    let url = this.config.paths.apiURL + "password/reset/check";
    let resetToken = getParameterByName('token')
    this.loadingAdd(loadingKey);

    if (resetToken !== null && resetToken !== "") {

        // API call
        let response = await this.dataLayer({
            url: url,
            cacheAge: 0,
            params: {
                resetToken: resetToken
            }
        });

        if (response && response.status && response.status === "OK" && response.data.c_utente) {
            this.formData = {
                pswreset: {
                    c_utente: response.data.c_utente
                }
            };
        } else {
            this.notificationAdd(
                langstore.t("password_reset_token_invalid", "Token non valido"),
                "pswresetko",
                2000,
                "error"
            );
            this.routeChange('/login');
        }

        this.loadingRemove(loadingKey);
    }
}

const passwordResetConfirm = async function (data) {
    let loadingKey = 'passwordResetProcedureConfirm';
    let url = this.config.paths.apiURL + "password/reset/confirm";
    let resetToken = getParameterByName('token')

    this.loadingAdd(loadingKey);

    if (resetToken !== null && resetToken !== "") {

        // API call
        let response = await this.dataLayer({
            url: url,
            cacheAge: 0,
            params: {
                ...data,
                resetToken: resetToken,
                c_utente: data.pswreset.c_utente
            }
        });

        if (response.status === "OK") {
            this.formData = toJS({});
            this.notificationAdd(
                langstore.t("password_reset_confirm_ok", "Procedura di reset password completata con successo"),
                "pswresetok",
                2000,
                "success"
            );
            this.routeChange('/login');
        }

        this.loadingRemove(loadingKey);
    }
}

const appReset = async function (appIndex) {
    console.log("deleting db", appIndex);

    // delete localstorage (all)
    clearStorage(appIndex);
    //localStorage.clear();

    // delete inxedDB (all)
    if (appIndex) {
        await deleteDB(appIndex, {});
    }
    return true;
}

const appUpgrade = async function () {

    // get current version
    let indexCurrent = localStorage.getItem(`app_index_${this.namespaceIndex}`) || "__new__";

    // get config version
    let indexNew = this.fullNamespace;

    // same version, do nothing
    if (indexNew === indexCurrent || this.routeParamsCurrent.unproctected === true) {
        localStorage.setItem(`app_index_${this.namespaceIndex}`, String(indexNew));
        return false;
    }

    // process different version
    await this.userLogout();
    await this.appReset(indexCurrent);
    this.log(`upgraded from ${indexCurrent} to ${indexNew}`, "OK");

    localStorage.setItem(`app_index_${this.namespaceIndex}`, String(indexNew));
    return true;
}

const languageChange = async function (language, notify) {

    this.loadingAdd("languageChange");

    var store = this;
    var requestBody = new Request({data: {language: language}});
    var request = {
        method: 'post', headers: {
            "Content-Type": "application/json",
            "x-access-token": store.userToken
        }
    };
    var url = store.config.paths.apiURL + "profilo/setlingua";

    request.body = JSON.stringify(requestBody);

    fetch(url, request).then(function (response) {
        return response.json();
    }).then(function (result) {
        var response = new Response(result);
    });

    runInAction(() => {
        this.language = language;
    });

    // get language bundle

    const token = this.userToken;
    let params = {
        "lingua": [language]
    }
    let bundle = {};
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + "languages/traduzioni",
        cacheAge: 0,
        params: params,
        userToken: token
    });

    if (response && response.data && response.data.traduzioni && response.data.traduzioni[language]) {
        bundle = response.data.traduzioni[language];
        langstore.addLanguageBundle(language, bundle);
        langstore.changeLanguage(language);
    }

    if (token) {
        //await this.updateStatiOrdine();
        //await this.updateStatiOrdineAttivo();
        //await this.updateStatiReclamo()
        await this.getUltimoRunOk();
    }

    this.loadingRemove("languageChange");

    return true;
}

const userProjectionOpen = function () {

    let userToken = this.userTokenRoot;
    let userInfo = this.uerInfoRoot;
    let userService = this.userServiceRoot;

    runInAction(() => {
        // projected as self until new selection is made
        // this.userToken = userToken;
        // this.uerInfoRoot = userInfo;
        // this.userServiceRoot = userService;

        this.userTokenRoot = userToken;
        this.uerInfoRoot = userInfo;
        this.userServiceRoot = userService;
    });

}

const userProjectionClose = function () {

    let rootToken = this.userTokenRoot;
    let rootInfo = this.uerInfoRoot;
    let rootService = this.userServiceRoot;

    runInAction(() => {
        this.userToken = rootToken;
        this.uerInfoRoot = rootInfo;
        this.userServiceRoot = rootService;
        this.userTokenRoot = null;
        this.uerInfoRoot = {};
        this.userServiceRoot = null;
    });
}

export {
    initCore,
    init,
    userLogin,
    userAuth,
    trySsoLogin,
    userLogout,
    menuLoad,
    userReset,
    languageChange,
    passwordReset,
    appUpgrade,
    appReset,
    startPasswordReset,
    passwordResetConfirm,
    //userProjectionOpen,
    //userProjectionClose,
}
